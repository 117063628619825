import './General.css';

const General = () => {
  return (
    <div className='general-wrapper'>
      <div className='general-inner-wrapper'>
        <h1>Find All Your Essentials Under One Roof</h1>
        <div className='fucking-test' data-aos="fade-right" data-aos-duration="600">
          <img src="next.png" alt="" />
          <h5><i>High security to entering premises via fingerprint access only</i></h5>
        </div>
        <div className='fucking-test' data-aos="fade-right" data-aos-duration="700">
          <img src="next.png" alt="" />
          <h5><i>Fully furnished and fully equipped workstations with the best and latest PCs
          and networking</i></h5>
        </div>
        <div className='fucking-test' data-aos="fade-right" data-aos-duration="800">
          <img src="next.png" alt="" />
          <h5><i>Professional printing services</i></h5>
        </div>
        <div className='fucking-test' data-aos="fade-right" data-aos-duration="900">
          <img src="next.png" alt="" />
          <h5><i>Have your private drawers (per workstation) and locker</i></h5>
        </div>
        <div className='fucking-test' data-aos="fade-right" data-aos-duration="1000">
          <img src="next.png" alt="" />
          <h5><i>Free access to our highly equipped kitchen area in addition to eating area</i></h5>
        </div>
        <div className='fucking-test' data-aos="fade-right" data-aos-duration="1100">
          <img src="next.png" alt="" />
          <h5><i>Receiving your physical shipments in case you are not at One Office (we shall send
            an SMS, WhatsApp message, & Email in case you receive any parcels)</i></h5>
        </div>
        <div className='fucking-test' data-aos="fade-right" data-aos-duration="1200">
          <img src="next.png" alt="" />
          <h5><i>Advanced meeting area equipped with the best facilities to ensure that your
          meetings are always professional and fruitful</i></h5>
        </div>
      
      </div>
    </div>
  );
}

export default General;